/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import About from '~/components/Pages/About';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulPage(slug: { eq: "about" }) {
        name
        sections {
          ...ContentPageSectionFragment
        }
      }
    }
  `);
  const { page } = data;
  return (
    <Layout>
      <Metadata
        title="About"
        description="We believe in a world where all humans have their natural needs met with quality care.
                     We aim to be a part of this necessary change, which we know requires time, patience, and conscious curation."
      />
      <About page={page} />
    </Layout>
  );
};

export default AboutPage;
