/** @jsx jsx */
import { Container, Box, Flex, Heading, Text, jsx, IconButton } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _ from 'lodash';
import { useRef } from 'react';
import RichTextRenderer from '~/components/Content/RichText';
import ShopSplashTextMarquee from '~/components/Generic/ShopSplashTextMarquee';
import Spacer from '~/components/Generic/Layout/Spacer';
import SvgImage from '~/components/Generic/Image/SVG';
import ImageMarquee from '~/components/Generic/Marquee/Image';
import useOnScreen from '~/hooks/components/use-on-screen';
import DignifiedYellow from '~/assets/images/dignified-yellow-circle.svg';

const About = ({ page }) => {
  const { sections } = page;
  const data = useStaticQuery(
    graphql`
      query {
        sustain: contentfulAsset(title: { eq: "sustain-icon" }) {
          description
          gatsbyImageData(quality: 80)
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
        productButton: contentfulAsset(title: { eq: "product-button-svg" }) {
          svg {
            content # SVG content optimized with SVGO
          }
          file {
            contentType
            url
          }
          gatsbyImageData(quality: 80)
        }
        augustGreyA: contentfulAsset(title: { eq: "august-grey-a" }) {
          gatsbyImageData(quality: 80)
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    `
  );
  const { augustGreyA, productButton } = data;
  // const imageNodes = _.map(sections[_.size(sections) - 1].marqueeImages, (marqueeImage) => ({
  //   gatsbyImageData: marqueeImage.gatsbyImageData,
  //   description: marqueeImage.altText,
  //   file: marqueeImage.image.file,
  //   link: marqueeImage.link,
  //   label: marqueeImage.label,
  // }));

  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const onScreen = useOnScreen(ref, '0px', true);
  const onScreen2 = useOnScreen(ref2, '0px', true);
  const onScreen3 = useOnScreen(ref3, '0px', true);

  return (
    <Container variant="fullwidth">
      <ShopSplashTextMarquee
        textArr={['SHOP OUR FAVES']}
        symbol="•"
        backgroundColor="canary"
        fontWeight="bold"
        linkTo="/shop"
      />
      <Spacer height={['1.5rem']} />
      <Container variant="fullwidth" sx={{ px: ['2rem', '5rem'] }}>
        {/** Section 0 | Heading */}
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          {/** TODO: Refactor this out */}
          <Heading
            as="h1"
            sx={{
              variant: 'text.altHeading',
              textAlign: 'center',
              width: ['30rem', '80rem'],
              mb: '2.7rem',
            }}
          >
            <RichTextRenderer richText={sections[0].heading} />
          </Heading>
          <Box
            ref={ref}
            className={`fade-in-up ${onScreen ? 'visible' : ''}`}
            sx={{
              height: ['auto', '29.7rem'],
              width: ['70%', '40.9rem'],
              overflow: 'hidden',
              '& > .gatsby-image-wrapper': {
                height: '105%',
                width: '100%',
              },
            }}
          >
            <GatsbyImage
              image={sections[0].images[0].gatsbyImageData}
              alt={sections[0].images[0].description}
            />
          </Box>
        </Flex>
        <Spacer height={['3.7rem']} />
        {/** Section 1 | Way of Working */}
        <Flex sx={{ justifyContent: 'center', position: 'relative' }}>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              width: [null, '84rem'],
              position: 'relative',
              mb: ['15rem', null, 0],
            }}
          >
            <Heading as="h2" variant="h2">
              <RichTextRenderer richText={sections[1].heading} />
            </Heading>
            <Spacer height={['3rem', '5rem']} />
            <Box
              sx={{
                alignSelf: ['flex-end', null, 'center'],
                width: ['63%', null, 'auto'],
                position: 'relative',
                pb: '15px',
              }}
            >
              <Text
                sx={{
                  fontSize: [1, 2, 3],
                  width: [null, null, '36rem'],
                }}
              >
                <RichTextRenderer richText={sections[1].copy} />
              </Text>
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  '.gatsby-image-wrapper': {
                    width: '100%',
                    height: '100%',
                  },
                  '.gatsby-image-wrapper > div': {
                    pb: '121.54% !important',
                  },
                }}
              >
                <Box
                  ref={ref2}
                  className={`fade-in-up ${onScreen2 ? 'visible' : ''}`}
                  sx={{
                    position: 'absolute',
                    top: ['1rem', null, '2.5rem'],
                    left: ['-12rem', null, '-24rem'],
                    height: ['10.9rem', null, '22.9rem'],
                    width: ['10.3rem', null, '21.5rem'],
                    transitionDelay: '0.5s',
                  }}
                >
                  <GatsbyImage
                    image={sections[1].images[0].gatsbyImageData}
                    alt={sections[1].images[0].description}
                  />
                </Box>
                <Box
                  ref={ref3}
                  className={`fade-in-up ${onScreen3 ? 'visible' : ''}`}
                  sx={{
                    position: 'absolute',
                    bottom: ['-12.5rem', null, '-33.3rem'],
                    left: ['8.5rem', null, '-4rem'],
                    height: ['13.2rem', null, '31.6rem'],
                    width: ['10.6rem', null, '25.6rem'],
                    zIndex: [0, null, 1],
                    transitionDelay: '0.75s',
                  }}
                >
                  <GatsbyImage
                    image={sections[1].images[1].gatsbyImageData}
                    alt={sections[1].images[1].description}
                  />
                </Box>
                <Box
                  className={`fade-in-up ${onScreen3 ? 'visible' : ''}`}
                  sx={{
                    position: 'absolute',
                    bottom: ['-15.5rem', null, '-28.2rem'],
                    left: ['-6rem', null, '20.5rem'],
                    height: ['15.6rem', null, '29.6rem'],
                    width: ['13.5rem', null, '25.6rem'],
                    transitionDelay: '1s',
                  }}
                >
                  <GatsbyImage
                    image={sections[1].images[2].gatsbyImageData}
                    alt={sections[1].images[2].description}
                    sx={{
                      img: {
                        width: '112% !important',
                        left: '-29px !important',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Spacer height={['10rem', null, '49.5rem']} />
        {/* * Section 3 | Core section */}
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            margin: '0 auto',
          }}
        >
          <DignifiedYellow
            className={`fade-in-up ${onScreen ? 'visible' : ''}`}
            sx={{
              height: ['20.5rem', '29.1rem', '51rem'],
              width: ['20.5rem', '29.1rem', '51rem'],
              position: 'absolute',
              top: ['-20rem', '-19rem', '-50rem'],
              right: ['-10rem', '-10rem', '-26rem'],
              zIndex: 5,
              animation: 'rotating 30s linear infinite',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: ['5rem', null, '-6rem'],
              width: ['24rem', null, '61.3rem'],
              left: '50%',
              transform: 'translateX(-50%)',
              opacity: 0.36,
            }}
          >
            <GatsbyImage image={augustGreyA.gatsbyImageData} />
          </Box>
          <Heading
            sx={{
              position: 'relative',
              zIndex: 1,
              margin: '0 auto',
              textAlign: ['center', null, 'left'],
              mt: [0, '70px', 0],
              fontSize: ['35px', '45px'],
              '& > p': { mb: '12px' },
              textTransform: 'uppercase',
            }}
          >
            <RichTextRenderer richText={sections[2].heading} />
          </Heading>
          <Spacer height={['3rem', '4rem', '5.5rem']} />
          {/* Individual Core Sections */}
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              borderBottom: '1px solid #e3232a',
              width: ['95%', '85%', '70%'],
              maxWidth: '613px',
              alignItems: 'center',
              justifyContent: ['center', 'space-between', 'flex-start'],
              pb: '12px',
              mt: ['-60px', '-40px', '-80px'],
            }}
          >
            <Text
              sx={{
                zIndex: 1,
                fontSize: '25px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                width: ['none', '40%', '40%'],
              }}
            >
              <RichTextRenderer richText={sections[4].heading} />
            </Text>
            <Text
              sx={{
                fontSize: ['12px'],
                width: ['100%', '50%', '60%'],
                color: 'oldBrick',
                lineHeight: '16px',
                textAlign: ['center', 'left', 'left'],
              }}
            >
              <RichTextRenderer richText={sections[4].copy} />
            </Text>
          </Flex>
          {/* 2 */}
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: ['center', 'space-between', 'flex-start'],
              borderBottom: '1px solid #e3232a',
              width: ['95%', '85%', '70%'],
              maxWidth: '613px',
              alignItems: 'center',
              pb: '12px',
            }}
          >
            <Text
              sx={{
                zIndex: 1,
                fontSize: '25px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                width: ['none', '40%', '40%'],
              }}
            >
              <RichTextRenderer richText={sections[5].heading} />
            </Text>
            <Text
              sx={{
                fontSize: ['12px'],
                width: ['100%', '50%', '60%'],
                color: 'oldBrick',
                lineHeight: '16px',
                textAlign: ['center', 'left', 'left'],
              }}
            >
              <RichTextRenderer richText={sections[5].copy} />
            </Text>
          </Flex>
          {/* 3 */}
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: ['center', 'space-between', 'flex-start'],
              borderBottom: '1px solid #e3232a',
              width: ['95%', '85%', '70%'],
              maxWidth: '613px',
              alignItems: 'center',
              pb: '12px',
              mb: '30px',
            }}
          >
            <Text
              sx={{
                zIndex: 1,
                fontSize: '25px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                textAlign: ['center', 'left'],
                width: ['none', '40%', '40%'],
              }}
            >
              <RichTextRenderer richText={sections[6].heading} />
            </Text>
            <Text
              sx={{
                fontSize: ['12px'],
                width: ['100%', '50%', '60%'],
                color: 'oldBrick',
                lineHeight: '16px',
                textAlign: ['center', 'left', 'left'],
              }}
            >
              <RichTextRenderer richText={sections[6].copy} />
            </Text>
          </Flex>

          <IconButton
            as={Link}
            to="/shop"
            title="to shop"
            sx={{
              height: ['4.9rem', '6.5rem', '7.8rem'],
              width: ['9.4rem', '12.5rem', '15rem'],
              zIndex: 1,
              '& > div': {
                height: '100%',
                width: '100%',
                '& > svg': {
                  height: '100%',
                  width: '100%',
                },
              },
            }}
          >
            <SvgImage svgImage={productButton} alt="explore more" />
          </IconButton>
        </Flex>
      </Container>
      <Spacer height={['10rem', null, '20rem']} />
      {/* <ImageMarquee images={imageNodes} heading="Meet the Team" hasLabels spinner /> */}
    </Container>
  );
};

export default About;
